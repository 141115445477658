import React, { useEffect } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import Header from '../components/Header';
import { useMediaQuery } from 'react-responsive';
import logo from '../assets/logo-lg.png';
import Footer from '../components/Footer';
import { FaEnvelopeOpenText, FaLaptop, FaPhone } from 'react-icons/fa';
import { scrollToTop } from '../components/ScrollHandler';


const ICON_SIZE = 60;

const Page = styled.div`
  position: relative;
  color: #061529;
  font-weight: 300;
  width: 100vw;
  //height: 100vh;
   min-height: calc(100vh - ${props => props.isMobile ? 100 : 150}px) ;
  font-family: Muli, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
    -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  a {
    text-decoration: none;
    color: #061529;
  }
`;

const HeroLogo = styled.div`
  position:absolute;
  width: 50%;
  top: -25px;
  left: -35px;
  background-image: url(${logo});
  background-size: contain;
  background-repeat: no-repeat;
  height:  ${props => (props.isMobile ? '200px;' : '300px;')};
`;

const Title = styled.div`
display: flex;
justify-content: center;
align-items: center;

`;

const TitleText = styled.div`
  font-size:  ${props => (props.isMobile ? '3rem;' : '4rem')};
  margin-left: 3vw;
`;


const Paragraph = styled.p`
 color: #061529;
 font-size: 1.6rem;
 font-weight: 400;
 margin-left: 15vw;
 margin-right: 15vw;
 text-align: center;
`;


const ContactUs = styled.img`
margin-top: 5vh;
height: 25vw;
border-radius: 15px;
`;

const Icons = styled.div`
  display: flex;
  align-items: ${props => (props.isMobile ? 'center' : 'baseline')};

  justify-content: space-between;
  width: ${props => (props.isMobile ? 100 : 60)}%;
  flex-wrap: wrap;
  flex-direction: ${props => (props.isMobile ? 'column' : 'row')};

`;

const icon = () => `
  color: #439c9c;
  padding: 5px;
`;

const Icon = styled.div`
  border-radius: 50%;
  border: 2px solid #439c9c;
  padding: 10px;
  width: 70px;
  height: 70px;
`;
const Phone = styled(FaPhone)`
  ${icon};
`;

const Mail = styled(FaEnvelopeOpenText)`
  ${icon};
`;

const Email = styled(FaLaptop)`
  ${icon};
`;

const ContactIcons = styled.div`
display: flex;
justify-content: center;
margin-top: 4vh;
margin-bottom: 4vh;
`;

const Text = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  margin-top: 10px;
  text-align: center;
`;

const ContactType = styled.div`
display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin: 10px;
    min-width: 160px;
`;


const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    width: 100vw;
  }
`;
const Emp = styled.span`
font-weight: 600;

`;
const Contact = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
  useEffect(() => {
    scrollToTop()
  },[])
  return (
    <>
      <GlobalStyle/>
      <Page isMobile={isMobile}>
        <Header isMobile={isMobile} alt/>
        <HeroLogo isMobile={isMobile}/>
        <Title>
          <TitleText isMobile={isMobile}>
            Get in touch...
          </TitleText>
        </Title>
        <Paragraph>
          We would love to hear from you. Get in touch and we can arrange a coffee or video meet.
        </Paragraph>
        <Paragraph>
          <ContactUs
            src={process.env.REACT_APP_S3_URL + '/images/contact-us/contact-us.jpg'}
          />
        </Paragraph>
        <ContactIcons>

          <Icons isMobile={isMobile}>
            <ContactType>
              <Icon>
                <Phone size={ICON_SIZE}/>
              </Icon>
              <Text>
                <Emp>Call us</Emp>
                <a href="tel:003332412244">0333 241 2244</a>

              </Text>
            </ContactType>
            <ContactType>
              <Icon>
                <Mail size={ICON_SIZE}/>
              </Icon>
              <Text>
                <Emp> Write to us</Emp>
                Office 4 <br/>28 Castle Street, Hertford <br/>Hertfordshire SG14 1HH
              </Text>
            </ContactType>
            <ContactType>
              <Icon>
                <Email size={ICON_SIZE}/>
              </Icon>
              <Text>
                <Emp> Email</Emp>
                <a href="mailto:contact@socrates.systems">
                  contact@socrates.systems
                </a>
              </Text>
            </ContactType>
          </Icons>
        </ContactIcons>


      </Page>
      <Footer isMobile={isMobile}/>
    </>
  );
};

export default Contact
