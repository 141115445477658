import React from 'react';
import styled, { keyframes } from 'styled-components';
import { fadeInUp } from 'react-animations';
import LazyLoad from 'react-lazyload';
import { FaCheck } from 'react-icons/fa'
import { useMediaQuery } from 'react-responsive';
import Footer from '../Footer';

const fadeInUpAnimation = keyframes`${fadeInUp}`;
const ICON_HEIGHT = 40;
const Page = styled.div`
  position: relative;
  color: white;
   width: 100%;
  font-family: Muli, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
    -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
   animation: 1s ${fadeInUpAnimation};
`;

const Content = styled.div`
  color: #061529;
  padding: 8vh 6vw;
  text-align: center;
 animation: 1s ${fadeInUpAnimation};

`;
const Heading = styled.div`
  font-size: 3.8em;
  margin-bottom: 5vh;
`;


const Integrations = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;

`;

const INTEGRATION_LIST = [
  'Payment Gateway',
  'Direct Debit',
  'Aggregators',
  'Medical Screening',
  'SMS Service',
  'Postcode Lookup'
];

const ListItem = styled.div`
  display: flex;
  justify-content: space-between;
  width: ${props => props.isMobile ? 80 : 25}%;
  margin-bottom: 2vh;

`;

const Tick = styled(FaCheck)`
  border-radius: 50%; 
  border: 10px solid #061529;
  padding: 10px;

`;

const Text = styled.div`
  font-size: ${props => props.isMobile ? 1 : 1.6}em;
  margin-left:  2vw;
  text-align: left;

`;
const Integration = ({ name, isMobile }) => (
  <ListItem isMobile={isMobile}>
    <Item>
      <Tick size={ICON_HEIGHT}/>
      <Text isMobile={isMobile}>{name}</Text>
    </Item>
  </ListItem>
);

const Item = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
`;

const ThirdPartyIntegrations = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' })
  return <Page>
    <LazyLoad height={200} offset={0}>
      <>
        <Content>
          <Heading>Third Party Integrations</Heading>

          <Integrations>
            {INTEGRATION_LIST.map((integration) => (
              <Integration name={integration} isMobile={isMobile}/>

            ))}
          </Integrations>

        </Content>
        <Footer isMobile={isMobile}/>
      </>
    </LazyLoad>
  </Page>
};

export default ThirdPartyIntegrations;
