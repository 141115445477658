import { useMediaQuery } from 'react-responsive';
import LazyLoad from 'react-lazyload';
import React from 'react';
import styled, { keyframes } from 'styled-components';
import { fadeInUp } from 'react-animations';
import {
  FaHeartbeat,
  FaLaptop,
  FaPaw,
  FaPlane,
  FaTractor,
  FaFileContract,
  FaHome
} from 'react-icons/fa';

import { GenIcon } from 'react-icons';

const ICON_HEIGHT = 80;
const MOBILE_ICON_HEIGHT = 30;
const fadeInUpAnimation = keyframes`${fadeInUp}`;

const Page = styled.div`
  position: relative;
  
  width: 100%;
  font-family: Muli, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
    -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
   animation: 1s ${fadeInUpAnimation};
`;


const Content = styled.div`
  color: #c9448a;
  padding: 8vh 6vw;;
  text-align: center;
  animation: 1s ${fadeInUpAnimation};

`;
const Heading = styled.span`
  font-size: 2.5em;
`;
const Intro = styled.p`
  margin-top: 50px;
  font-size: 1.8em;
  text-align: center;

`;

const icon = () => `
  color: #c9448b;
  margin: 1.5vw
`;
const LogoList = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Title = styled.div`
  color: #c9448b;
  font-size: ${props => props.isMobile ? 1 : 2}rem;
  font-weight: 700;
`;

const Logo = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
   align-items: center;

`;
const Logos = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

`;

const Icon = styled.div`
    border-radius: 50%;
    border: 2px solid  #c9448b;
    margin: 4vw 4vw 2vw;
    height:  ${props => props.isMobile ? MOBILE_ICON_HEIGHT + 15 : ICON_HEIGHT + 60}px;
    width:  ${props => props.isMobile ? MOBILE_ICON_HEIGHT + 15 : ICON_HEIGHT + 60}px;
    display: flex;
    justify-content: center;
    align-items: center;
`;
const FaCyber = function (props) {
  return GenIcon({
    "tag": "svg",
    "attr": { "viewBox": "0 0 512 512" },
    "child": [{
      "tag": "path",
      "attr": { "d": "M224,192a16,16,0,1,0,16,16A16,16,0,0,0,224,192ZM466.5,83.68l-192-80A57.4,57.4,0,0,0,256.05,0a57.4,57.4,0,0,0-18.46,3.67l-192,80A47.93,47.93,0,0,0,16,128C16,326.5,130.5,463.72,237.5,508.32a48.09,48.09,0,0,0,36.91,0C360.09,472.61,496,349.3,496,128A48,48,0,0,0,466.5,83.68ZM384,256H371.88c-28.51,0-42.79,34.47-22.63,54.63l8.58,8.57a16,16,0,1,1-22.63,22.63l-8.57-8.58C306.47,313.09,272,327.37,272,355.88V368a16,16,0,0,1-32,0V355.88c0-28.51-34.47-42.79-54.63-22.63l-8.57,8.58a16,16,0,0,1-22.63-22.63l8.58-8.57c20.16-20.16,5.88-54.63-22.63-54.63H128a16,16,0,0,1,0-32h12.12c28.51,0,42.79-34.47,22.63-54.63l-8.58-8.57a16,16,0,0,1,22.63-22.63l8.57,8.58c20.16,20.16,54.63,5.88,54.63-22.63V112a16,16,0,0,1,32,0v12.12c0,28.51,34.47,42.79,54.63,22.63l8.57-8.58a16,16,0,0,1,22.63,22.63l-8.58,8.57C329.09,189.53,343.37,224,371.88,224H384a16,16,0,0,1,0,32Zm-96,0a16,16,0,1,0,16,16A16,16,0,0,0,288,256Z" }
    }]
  })(props);
};


const Travel = styled(FaPlane)`
  ${icon};
`;
const Pet = styled(FaPaw)`
  ${icon};
`;
const Health = styled(FaHeartbeat)`
  ${icon};
`;

const Gadget = styled(FaLaptop)`
  ${icon};
  
`;
const Agri = styled(FaTractor)`
  ${icon};
`;
const Cyber = styled(FaCyber)`
  ${icon};
`;

const Home = styled(FaHome)`
  ${icon};
`;

const Liability = styled(FaFileContract)`
  ${icon};
`;


const Challenge = styled.div`
  font-size: 1.6em;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 3vh;
  padding: 3vw;
`;

const OurProducts = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
  const iconHeight = isMobile ? MOBILE_ICON_HEIGHT : ICON_HEIGHT;
  return <Page>
    <LazyLoad height={200} offset={0}>
      <>
        <Content>
          <Heading>Our Products</Heading>
          <Intro>
            Socrates Systems provides a range of insurance products from Travel to Agriculture to Pet Insurance.
          </Intro>
          <Logos>
            <LogoList>
              <Logo>
                <Icon isMobile={isMobile}>
                  <Travel size={iconHeight}/>
                </Icon>
                <Title isMobile={isMobile}>Travel</Title>
              </Logo>
              <Logo>
                <Icon isMobile={isMobile}>
                  <Agri size={iconHeight}/>
                </Icon>
                <Title isMobile={isMobile}>Agriculture</Title>
              </Logo>
            </LogoList>
            <LogoList>
              <Logo>
                <Icon isMobile={isMobile}>
                  <Pet size={iconHeight}/>
                </Icon>
                <Title isMobile={isMobile}>Pet</Title>
              </Logo>
              <Logo>
                <Icon isMobile={isMobile}>
                  <Cyber size={iconHeight}/>
                </Icon>
                <Title isMobile={isMobile}>Cyber</Title>
              </Logo>
            </LogoList>
            <LogoList>
              <Logo>
                <Icon isMobile={isMobile}>
                  <Health size={iconHeight}/>
                </Icon>
                <Title isMobile={isMobile}>Health</Title>
              </Logo>
              <Logo>
                <Icon isMobile={isMobile}>
                  <Home size={iconHeight}/>
                </Icon>
                <Title isMobile={isMobile}>Home</Title>
              </Logo>
            </LogoList>
            <LogoList>
              <Logo>
                <Icon isMobile={isMobile}>
                  <Gadget size={iconHeight}/>
                </Icon>
                <Title isMobile={isMobile}>Gadget</Title>
              </Logo>
              <Logo>
                <Icon isMobile={isMobile}>
                  <Liability size={iconHeight}/>
                </Icon>
                <Title isMobile={isMobile}>Liability</Title>
              </Logo>
            </LogoList>


          </Logos>
          <Challenge>
            <div><b>Don’t see your product above?</b></div>
            <div> Challenge us - we are always looking for a new venture.</div>
          </Challenge>
        </Content>

      </>

    </LazyLoad>

  </Page>
};

export default OurProducts;
