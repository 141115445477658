import React, { useEffect } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import Header from '../components/Header';
import { useMediaQuery } from 'react-responsive';
import logo from '../assets/logo-lg.png';
import Footer from '../components/Footer';
import { scrollToTop } from '../components/ScrollHandler';

const Page = styled.div`
  position: relative;
  color: #061529;
  font-weight: 300;
  width: 100vw;
  //height: 100vh;
   min-height: calc(100vh - ${props => props.isMobile ? 100 : 150}px) ;
  font-family: Muli, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
    -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;

const HeroLogo = styled.div`
  position:absolute;
  width: 50%;
  top: -25px;
  left: -35px;
  background-image: url(${logo});
  background-size: contain;
  background-repeat: no-repeat;
  height:  ${props => (props.isMobile ? '200px;' : '300px;')};
`;

const Title = styled.div`
display: flex;
justify-content: center;
align-items: center;

`;

// const TheBoss = styled.div`
//   height:  ${props => (props.isMobile ? '20vw' : '15vh')};
//   width:  ${props => (props.isMobile ? '20vw' : '15vh')};
//   position: relative;
//   overflow: hidden;
//   border-radius: 50%;
//
// `;

const TitleText = styled.div`
  font-size:  ${props => (props.isMobile ? '3rem;' : '4rem')};
  margin-left: 3vw;
`;


// const Circle = styled.img`
//   display: inline;
//   margin: 0 auto;
//   height: auto;
//   width: 100%;
// `;

const Paragraph = styled.p`
 color: #061529;
 font-size: 1.2rem;
 margin-left: 10vw;
 margin-right: 10vw;

`;

const Awards = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
  flex-direction: column;
  align-items: flex-end;
  //padding: 3vh;
  width: ${props => (props.small ? 100 : 220)}px;
  padding-right: 10px;
  //position: relative;
`;


const Montage = styled.div`
display: flex;
height: ${props => (props.small ? 200 : 350)}px;
align-content: center;
align-items: center;
justify-content: center;

`;

const Test = styled.div`
width: ${props => (props.small ? 385 : 820)}px;
display: flex;

`;
const Winner = styled.img`
width: ${props => (props.small ? 70 : 150)}px;

 
`;

const Finalist = styled.img`
width: ${props => (props.small ? 100 : 220)}px;
`;

const Photos = styled.div`
position:relative;
height: ${props => (props.small ? 150 : 300)}px;
width: ${props => (props.small ? 250 : 500)}px;

`;
const AwardSquare = styled.img`
height: ${props => (props.small ? 150 : 300)}px;
`;
const AwardCircleLarge = styled.img`
height: ${props => (props.small ? 100 : 220)}px;
position: absolute;
right: ${props => (props.small ? -30 : -90)}px;
bottom: 0;
`;
const AwardCircleSmall = styled.img`
height: ${props => (props.small ? 70 : 150)}px;
position: absolute;
right: 0;
bottom: ${props => (props.small ? -20 : -50)}px;
`;


const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    width: 100vw;
  }
`;


const AboutUs = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
  const small =  useMediaQuery({ query: '(max-width: 800px)' });
  useEffect(() => {
    scrollToTop()
  },[])

  return (
    <>
      <GlobalStyle/>
      <Page isMobile={isMobile}>
        <Header isMobile={isMobile} alt/>
        <HeroLogo isMobile={isMobile}/>
        <Title>
          {/*<TheBoss isMobile={isMobile}>*/}
          {/*  <Circle*/}
          {/*    fluid*/}
          {/*    roundedCircle*/}
          {/*    src={process.env.REACT_APP_S3_URL + '/images/team/jody.png'}*/}
          {/*  />*/}
          {/*</TheBoss>*/}
          <TitleText isMobile={isMobile}>
            Our Story
          </TitleText>
        </Title>
        <Paragraph>

          Socrates Systems Limited is a Company that’s all about online system development and e-commerce websites,
          specialising in IT solutions for the insurance industry. Many of the Socrates team have worked specifically in
          the travel insurance sector for over ten years, developing and perfecting systems.
        </Paragraph>
        <Paragraph>
          Socrates came into being out of frustration. When our Managing Director, Jody Brooks, worked for another
          organisation some years ago, part of his role was to implement workable systems that at the time could only be
          outsourced. But by outsourcing, the IT company would rarely get it ‘right’ or there would be a compromise,
          shoehorning functionality into a system that wasn’t built specifically for the organisation. The quoting
          mechanisms were frequently clunky, and reporting systems were never ideal.
        </Paragraph>
        <Paragraph>
          So being a bit of a perfectionist, Jody set to creating his own system, a system that worked for the business
          -
          not the business being dictated by the system. He gathered people he knew and trusted around him to form a
          strong team and those people are still at Socrates now.
          That system, now called Socrates Online manages the sale of insurance products through both B2C and B2B
          channels
          and brings together the best features of the team’s years of experience. We take a lot of pride in what we do
          and were over the moon to be not only recognised but credited within the Industry with an ITIJ award in
          2017.
        </Paragraph>

        <Montage small={small}>
          <Test small={small}>
            <Awards small={small}>
              <Winner
                small={small}
                src={process.env.REACT_APP_S3_URL + '/images/footer/winner.png'}
              />

              <Finalist
                small={small}
                src={process.env.REACT_APP_S3_URL + '/images/footer/finalist.png'}
              />
            </Awards>
            <Photos small={small}>
              <AwardSquare
                small={small}
                src={process.env.REACT_APP_S3_URL + '/images/team/award-square.png'}
              />
              <AwardCircleLarge
                small={small}
                src={process.env.REACT_APP_S3_URL + '/images/team/award-large-circle.png'}
              />
              <AwardCircleSmall
                small={small}
                src={process.env.REACT_APP_S3_URL + '/images/team/award-small-circle.png'}
              />
            </Photos>
          </Test>
        </Montage>
      </Page>

      <Footer isMobile={isMobile}/>

    </>
  );
};

export default AboutUs
