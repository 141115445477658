import React from 'react';
import styled, { keyframes } from 'styled-components';
import { fadeInLeftBig } from 'react-animations';
import { FaPlane, FaPaw, FaHome, FaCar, FaLaptop, FaUserInjured, } from 'react-icons/fa';
const textMargin = 255;
const mobileTextMargin = 50
const fadeInLeftAnimation = keyframes`${fadeInLeftBig}`;
const ICON_HEIGHT = 40;
const MOBILE_ICON_HEIGHT = 30;

const LogosList = styled.div`
  display: flex;
  align-items: baseline;
  margin-bottom: 20px;
  margin-top: 20px;
  margin-left: ${props => props.isMobile ? mobileTextMargin : textMargin}px;
`;

const icon = () => `
  color: white;
  border-radius: 50%;
  border: 2px solid white;
  padding: 5px;
  margin-right: 15px;
`;

const StyledPlane = styled(FaPlane)`
  ${icon};
  animation: 2.4s ${fadeInLeftAnimation};
`;

const StyledPaw = styled(FaPaw)`
  ${icon};
  animation: 2s ${fadeInLeftAnimation};
`;

const StyledHome = styled(FaHome)`
  ${icon};
  animation: 1.6s ${fadeInLeftAnimation};
`;

const StyledCar = styled(FaCar)`
  ${icon};
  animation: 1.2s ${fadeInLeftAnimation};
`;

const StyledLaptop = styled(FaLaptop)`
  ${icon};
  animation: 0.8s ${fadeInLeftAnimation};
  
`;

const StyledMan = styled(FaUserInjured)`
  ${icon};
  animation: 0.6s ${fadeInLeftAnimation};
`;

const Logos = ({isMobile}) => {
  const iconHeight = isMobile ? MOBILE_ICON_HEIGHT : ICON_HEIGHT
  return <LogosList isMobile={isMobile}>
    <StyledPlane size={iconHeight}/>
    <StyledPaw size={iconHeight}/>
    <StyledHome size={iconHeight}/>
    <StyledCar size={iconHeight}/>
    <StyledLaptop size={iconHeight}/>
    <StyledMan size={iconHeight}/>
  </LogosList>
};
export default Logos;
