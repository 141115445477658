import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const FooterLogos = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
const FooterMain = styled.div`
  background-color:  #061529; ;
  height: ${props => props.isMobile ? 100 : 150}px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100vw;
  font-family: Muli, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;

const FooterImage = styled.img`
height: ${props => props.isMobile ? 40 : 75}px;
margin : 5px;

`;
const FooterText = styled.div`
    padding: 10px;
    font-size: 0.7em;
    text-align: center;
    color: white;
`;

const FooterLink = styled(Link)`

  margin-left: 10px;
  margin-right: 10px;
  color: white;
  font-size: 0.7em;
  &:hover {
    text-decoration: underline;
  }
`;

const StyledMenuBar = styled.div`
  padding: 5px;
  display: flex;
  justify-content: space-evenly;
`;
const MenuBar = ({ isMobile, alt }) => {
  return <StyledMenuBar alt={alt} isMobile={isMobile}>
    <FooterLink
      to={'/about-us'}
      isMobile={isMobile}
      alt={alt}
    >
      About Us
    </FooterLink>
    <FooterLink
      to={'/privacy'}
      isMobile={isMobile}
      alt={alt}
    >
      Privacy
    </FooterLink>
    <FooterLink
      to={'/contact'}
      isMobile={isMobile}
      alt={alt}
    >
      Contact
    </FooterLink>
  </StyledMenuBar>;
};

const Footer = ({isMobile}) => {
  return (
    <FooterMain>
      <FooterLogos>
        <FooterImage
          isMobile={isMobile}
          fluid
          src={process.env.REACT_APP_S3_URL + '/images/footer/logo.png'}
        />
        <FooterImage
          isMobile={isMobile}
          fluid
          src={process.env.REACT_APP_S3_URL + '/images/footer/winner.png'}
        />
        <FooterImage
          isMobile={isMobile}
          fluid
          src={process.env.REACT_APP_S3_URL + '/images/footer/finalist.png'}
        />
      </FooterLogos>
      <FooterText>
        Socrates Systems Limited. Registered in England. Company No. 06771426 Registered Office: East Wing, Goffs Oak
        House, Goffs Oak, Herts, EN7 5BW.
      </FooterText>
      <MenuBar/>
    </FooterMain>)
};

export default Footer;
