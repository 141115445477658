import React from 'react';
import styled, { keyframes } from 'styled-components';
import { fadeInUp } from 'react-animations';
import LazyLoad from 'react-lazyload';
import { FaQuoteLeft, FaQuoteRight } from 'react-icons/fa'

const fadeInUpAnimation = keyframes`${fadeInUp}`;

const Page = styled.div`
  position: relative;
  color: white;
  width: 100%;
  font-family: Muli, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
    -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
   animation: 1s ${fadeInUpAnimation};
`;


const Quote = styled.div`
  margin-top: 50px;
  margin-left: 2vw;
  margin-right: 2vw;
  font-style: italic;
  font-size: 1.2em;
  text-align: center;
`;

const QuoteAttribution = styled.div`
  margin-top: 15px;
  font-size: 1em;
  margin-left: 2vw;
  margin-right: 2vw;
  text-align: right;
`;

const Intro = styled.p`
  margin-top: 50px;
  font-size: 1.8em;
  text-align: center;
`;

const Content = styled.div`
  background-color: #f6f6f6;
  color: #061529;
  padding: 8vh 6vw;
  text-align: center;
  animation: 1s ${fadeInUpAnimation};

`;

const Heading = styled.span`
  font-size: 3.8em;
`;

const WhatWeDo = () => {
  return <Page>

    <LazyLoad height={200} offset={0}>
      <>
        <Content>
          <Heading>What we do</Heading>
          <Intro>
            We provide secure e-commerce systems, specialising in insurance solutions for both the broker and public
            markets, internationally, from rate management all the way through to purchase and data analysis.
          </Intro>
          <p>
            <Quote>
              <FaQuoteLeft /> Big data is becoming more and more important and having systems designed for the industry
              to make best use of data is vital to the industry and this company does just that.<FaQuoteRight/>
            </Quote>
            <QuoteAttribution>
              - Judges Comment, 2017 ITIJ Awards
            </QuoteAttribution>
          </p>
        </Content>
      </>
    </LazyLoad>
  </Page>
};

export default WhatWeDo;
