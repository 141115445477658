import React from 'react';
import { createGlobalStyle } from "styled-components"
import PartnerCarousel from '../components/PartnerCarousel';
import Home from '../components/Main/Home';
import WhatWeDo from '../components/Main/WhatWeDo';
import OurProducts from '../components/Main/OurProducts';
import SecureSystems from '../components/Main/SecureSystems';
import ThirdPartyIntegrations from '../components/Main/ThirdPartyIntegrations';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    width: 100vw;
  }
`;

const Main = () => {
  return (
    <>
      <GlobalStyle/>
      <Home/>
      <PartnerCarousel/>
      <WhatWeDo/>
      <OurProducts/>
      <SecureSystems/>
      <ThirdPartyIntegrations/>
    </>
  )
};


export default Main;
