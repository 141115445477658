import React from 'react';

const Background = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 778.9 824.8">
    <g transform="translate(-591.6 -107)">
      <path d="M820.5,574.3v55l45.8-27.5Z" fill="#5abab5"/>
      <path d="M866.3,601.8v-55l-45.8,27.5Z" fill="#44ae9d"/>
      <path d="M774.7,602.4v55l45.8-27.5Z" fill="#4ab19f"/>
      <path d="M774.7,712.4v-55l-45.9,27.5Z" fill="#87c3a9"/>
      <path d="M820.5,629.3v55l45.8-27.5Z" fill="#7fd6d6"/>
      <path d="M866.3,711.8v-55l-45.8,27.5Z" fill="#7ac9ca"/>
      <path d="M774.7,657.4v55l45.8-27.5Z" fill="#5abab5"/>
      <path d="M820.5,684.9v-55l-45.8,27.5Z" fill="#80bdba"/>
      <path d="M866.3,656.8v55l45.9-27.5Z" fill="#7cb8aa"/>
      <path d="M912.2,574.3v-55l-45.9,27.5Z" fill="#84a568"/>
      <path d="M866.3,546.8v55l45.9-27.5Z" fill="#44ae9d"/>
      <path d="M912.2,629.3v-55l-45.9,27.5Z" fill="#7bb6a4"/>
      <path d="M866.3,601.8v55l45.9-27.5Z" fill="#80b7a1"/>
      <path d="M912.2,684.3v-55l-45.9,27.5Z" fill="#c4b460"/>
      <path d="M683.2,711.6v-55l-45.8,27.5Z" fill="#88c5c2"/>
      <path d="M683.3,656.6l-.1,55,45.9-27.5Z" fill="#87bea1"/>
      <path d="M912.2,519.3v55L958,546.8Z" fill="#b9b760"/>
      <path d="M958,601.8v-55l-45.8,27.5Z" fill="#bcb25e"/>
      <path d="M1278.8,299.5v55l45.9-27.5Z" fill="#b89c5e"/>
      <path d="M958,656.8v-55l-45.8,27.5Z" fill="#f3a94f"/>
      <path d="M912.2,629.3v55L958,656.8Z" fill="#e27b64"/>
      <path d="M958,711.8v-55l-45.8,27.5Z" fill="#f1836d"/>
      <path d="M637.4,629.9v55l45.8-27.5Z" fill="#71aab6"/>
      <path d="M1003.8,464.5v-55L958,437Z" fill="#baad5f"/>
      <path d="M958,656.8v55l45.8-27.5Z" fill="#f7a379"/>
      <path d="M1003.8,574.3v-55L958,546.8Z" fill="#cec46e"/>
      <path d="M912.2,464.5v55L958,492Z" fill="#cec46e"/>
      <path d="M1003.8,629.3v-55L958,601.8Z" fill="#f2cf52"/>
      <path d="M958,601.8v55l45.8-27.5Z" fill="#e69335"/>
      <path d="M1003.8,684.3v-55L958,656.8Z" fill="#ea86bd"/>
      <path d="M1003.8,519.3v-55L958,491.8Z" fill="#e0ce86"/>
      <path d="M958,491.8v55l45.8-27.5Z" fill="#d0bd69"/>
      <path d="M1003.8,519.3v55l45.9-27.5Z" fill="#efaa4f"/>
      <path d="M1049.7,601.8v-55l-45.9,27.5Z" fill="#db6d91"/>
      <path d="M1003.8,574.3v55l45.9-27.5Z" fill="#dcc771"/>
      <path d="M1049.7,656.8v-55l-45.9,27.5Z" fill="#b388ad"/>
      <path d="M958,711.8v55l45.8-27.5Z" fill="#8fb6b4"/>
      <path d="M1003.8,739.3v-55L958,711.8Z" fill="#88b1b0"/>
      <path d="M1003.8,464.3v55l45.9-27.5Z" fill="#f8cc78"/>
      <path d="M1095.5,464.5v-55L1049.7,437Z" fill="#eec573"/>
      <path d="M912.2,684.3v55L958,711.8Z" fill="#88a4ac"/>
      <path d="M1095.5,574.3v-55l-45.8,27.5Z" fill="#eeeb79"/>
      <path d="M1049.7,546.8v55l45.8-27.5Z" fill="#ea86bd"/>
      <path d="M1095.5,629.3v-55l-45.8,27.5Z" fill="#de80b3"/>
      <path d="M1049.7,601.8v55l45.8-27.5Z" fill="#9f7a9b"/>
      <path d="M1095.5,684.3v-55l-45.8,27.5Z" fill="#9e7197"/>
      <path d="M1049.7,491.8v55l45.8-27.5Z" fill="#f2be4e"/>
      <path d="M1370.5,739.3v-55l-45.8,27.5Z" fill="#5abab5"/>
      <path d="M1324.7,656.8v55l45.8-27.5Z" fill="#45a4ad"/>
      <path d="M1370.5,684.3v-55l-45.8,27.5Z" fill="#4ab19f"/>
      <path d="M1324.7,601.8v55l45.8-27.5Z" fill="#87c3a9"/>
      <path d="M1370.5,629.3v-55l-45.8,27.5Z" fill="#7fd6d6"/>
      <path d="M1324.7,546.8v55l45.8-27.5Z" fill="#7ac9ca"/>
      <path d="M1370.5,794.3v-55l-45.8,27.5Z" fill="#5abab5"/>
      <path d="M1324.7,711.8v55l45.8-27.5Z" fill="#80bdba"/>
      <path d="M1324.7,601.8v-55l-45.9,27.5Z" fill="#7cb8aa"/>
      <path d="M1278.8,684.3v55l45.9-27.5Z" fill="#84a568"/>
      <path d="M1278.8,629.3v55l45.9-27.5Z" fill="#7bb6a4"/>
      <path d="M1324.7,656.8v-55l-45.9,27.5Z" fill="#6ba58c"/>
      <path d="M1278.8,574.3v55l45.9-27.5Z" fill="#c4b460"/>
      <path d="M1278.8,739.3v55l45.9-27.5Z" fill="#88c5c2"/>
      <path d="M1324.7,766.8v-55l-45.9,27.5Z" fill="#87bea1"/>
      <path d="M1278.8,739.3v-55L1233,711.8Z" fill="#b9b760"/>
      <path d="M1233,656.8v55l45.8-27.5Z" fill="#bcb25e"/>
      <path d="M1278.8,684.3v-55L1233,656.8Z" fill="#b89c5e"/>
      <path d="M1233,601.8v55l45.8-27.5Z" fill="#f3a94f"/>
      <path d="M1278.8,629.3v-55L1233,601.8Z" fill="#e27b64"/>
      <path d="M1233,546.8v55l45.8-27.5Z" fill="#f1836d"/>
      <path d="M1278.8,794.3v-55L1233,766.8Z" fill="#71aab6"/>
      <path d="M1233,711.8v55l45.8-27.5Z" fill="#baad5f"/>
      <path d="M1233,601.8v-55l-45.8,27.5Z" fill="#f8cc78"/>
      <path d="M1187.2,684.3v55l45.8-27.5Z" fill="#cec46e"/>
      <path d="M1233,711.8v-55l-45.8,27.5Z" fill="#cec46e"/>
      <path d="M1187.2,629.3v55l45.8-27.5Z" fill="#f2cf52"/>
      <path d="M1233,656.8v-55l-45.8,27.5Z" fill="#e69335"/>
      <path d="M1187.2,574.3v55l45.8-27.5Z" fill="#d8b163"/>
      <path d="M1187.2,739.3v55l45.8-27.5Z" fill="#e0ce86"/>
      <path d="M1233,766.8v-55l-45.8,27.5Z" fill="#d0bd69"/>
      <path d="M1187.2,739.3v-55l-45.9,27.5Z" fill="#efaa4f"/>
      <path d="M1141.3,656.8v55l45.9-27.5Z" fill="#db6d91"/>
      <path d="M1187.2,684.3v-55l-45.9,27.5Z" fill="#dcc771"/>
      <path d="M1141.3,601.8v55l45.9-27.5Z" fill="#b388ad"/>
      <path d="M1187.2,629.3v-55l-45.9,27.5Z" fill="#8fb6b4"/>
      <path d="M1187.2,794.3v-55l-45.9,27.5Z" fill="#f8cc78"/>
      <path d="M1141.3,711.8v55l45.9-27.5Z" fill="#eec573"/>
      <path d="M1141.3,601.8v-55l-45.8,27.5Z" fill="#88a4ac"/>
      <path d="M1049.7,767v55.2l45.8-27.6Z" transform="translate(0 -0.2)" fill="#e094c1"/>
      <path d="M1141.3,711.8v-55l-45.8,27.5Z" fill="#ea86bd"/>
      <path d="M1095.5,629.3v55l45.8-27.5Z" fill="#de80b3"/>
      <path d="M1141.3,656.8v-55l-45.8,27.5Z" fill="#9f7a9b"/>
      <path d="M1095.5,574.3v55l45.8-27.5Z" fill="#9e7197"/>
      <path d="M1095.5,739.3v55l45.8-27.5Z" fill="#f0ab43"/>
      <path d="M1141.3,766.8v-55l-45.8,27.5Z" fill="#f2be4e"/>
      <path d="M1187.2,519.3v-55l-45.9,27.5Z" fill="#87c3a9"/>
      <path d="M1187.2,574.3v-55l-45.9,27.5Z" fill="#7ac9ca"/>
      <path d="M1187.2,519.3v55l45.8-27.5Z" fill="#7cb8aa"/>
      <path d="M1187.2,464.3v55l45.8-27.5Z" fill="#80b7a1"/>
      <path d="M1233,546.8v-55l-45.8,27.5Z" fill="#7ac9ca"/>
      <path d="M1233,491.8v55l45.8-27.5Z" fill="#4db284"/>
      <path d="M1049.7,711.8v-55l-45.9,27.5Z" fill="#977a99"/>
      <path d="M1049.7,656.8v55l45.8-27.5Z" fill="#b786ce"/>
      <path d="M1049.7,766.8v-55l-45.9,27.5Z" fill="#8a81aa"/>
      <path d="M1003.8,629.3v55l45.9-27.5Z" fill="#9e7197"/>
      <path d="M1095.5,519.3v55l45.8-27.5Z" fill="#efaa4f"/>
      <path d="M1049.7,822.2V767l-45.9,27.6Z" transform="translate(0 -0.2)" fill="#e598b7"/>
      <path d="M1049.7,382v55l45.8-27.5Z" fill="#f8cc78"/>
      <path d="M1141.3,546.8v-55l-45.8,27.5Z" fill="#eec573"/>
      <path d="M1003.8,684.3v55l45.9-27.5Z" fill="#dd85a6"/>
      <path d="M1095.5,794.3v-55l-45.8,27.5Z" fill="#de80b3"/>
      <path d="M1049.7,711.8v55l45.8-27.5Z" fill="#9f7a9b"/>
      <path d="M1278.8,519.3v55l45.9-27.5Z" fill="#45a4ad"/>
      <path d="M1278.8,574.3v-55L1233,546.8Z" fill="#44ae9d"/>
      <path d="M1003.8,739.3v55l45.9-27.5Z" fill="#bdadcc"/>
      <path d="M1003.8,794.3v-55L958,766.8Z" fill="#ab8cb1"/>
      <path d="M1370.5,519.3v-55l-45.8,27.5Z" fill="#b9b760"/>
      <path d="M1278.8,519.3v-55L1233,491.8Z" fill="#bcb25e"/>
      <path d="M1324.7,546.8v-55l-45.9,27.5Z" fill="#baad5f"/>
      <path d="M1370.5,574.3v-55l-45.8,27.5Z" fill="#cec46e"/>
      <path d="M1278.8,464.3v55l45.9-27.5Z" fill="#cec46e"/>
      <path d="M1324.7,491.8v55l45.8-27.5Z" fill="#d0bd69"/>
      <path d="M912.2,739.3v-55l-45.9,27.5Z" fill="#88b1b0"/>
      <path d="M958,766.8v-55l-45.8,27.5Z" fill="#88b1b0"/>
      <path d="M866.3,766.8v-55l-45.8,27.5Z" fill="#87c3a9"/>
      <path d="M820.5,739.3v55l45.8-27.5Z" fill="#7fd6d6"/>
      <path d="M912.2,739.3v55L958,766.8Z" fill="#7ac9ca"/>
      <path d="M820.5,684.3v55l45.8-27.5Z" fill="#7cb8aa"/>
      <path d="M912.2,794.3v-55l-45.9,27.5Z" fill="#80bdba"/>
      <path d="M1233,492V437l-45.8,27.5Z" fill="#44ae9d"/>
      <path d="M1324.7,492V437l-45.9,27.5Z" fill="#84a568"/>
      <path d="M1233,437v55l45.8-27.5Z" fill="#5abab5"/>
      <path d="M1324.7,437V382l-45.9,27.5Z" fill="#88c5c2"/>
      <path d="M1278.8,409.5v55l45.9-27.5Z" fill="#87bea1"/>
      <path d="M1324.7,437v55l45.8-27.5Z" fill="#b9b760"/>
      <path d="M1324.7,382v55l45.8-27.5Z" fill="#71aab6"/>
      <path d="M1370.5,464.5v-55L1324.7,437Z" fill="#baad5f"/>
      <path d="M1095.5,409.5v55l45.8-27.5Z" fill="#efaa4f"/>
      <path d="M1095.5,519.3v-55l-45.8,27.5Z" fill="#db6d91"/>
      <path d="M1141.3,492V437l-45.8,27.5Z" fill="#eec573"/>
      <path d="M1187.2,464.5v-55L1141.3,437Z" fill="#eeeb79"/>
      <path d="M1049.7,492V437l-45.9,27.5Z" fill="#ea86bd"/>
      <path d="M1049.7,436.8v55l45.8-27.5Z" fill="#f2be4e"/>
      <path d="M1141.3,491.8v55l45.9-27.5Z" fill="#7fd6d6"/>
      <path d="M820.5,574.9v-55l-45.8,27.5Z" fill="#7bb6a4"/>
      <path d="M774.7,547.4v55l45.8-27.5Z" fill="#5abab5"/>
      <path d="M637.4,739.1v-55l-45.8,27.5Z" fill="#87c3a9"/>
      <path d="M728.8,629.9v55l45.9-27.5Z" fill="#7cb8aa"/>
      <path d="M774.7,711.8v55l45.8-27.5Z" fill="#7ac9ca"/>
      <path d="M774.7,766.6v-55l-45.9,27.5Z" fill="#80bdba"/>
      <path d="M820.5,739.9v-55l-45.8,27.5Z" fill="#5abab5"/>
      <path d="M1278.8,354.5v55l45.9-27.5Z" fill="#f3a94f"/>
      <path d="M1324.7,382V327l-45.9,27.5Z" fill="#e27b64"/>
      <path d="M1324.7,327v55l45.8-27.5Z" fill="#f1836d"/>
      <path d="M1233,327v55l45.8-27.5Z" fill="#f8cc78"/>
      <path d="M1278.8,409.5v-55L1233,382Z" fill="#e69335"/>
      <path d="M1233,217v55l45.8-27.5Z" fill="#f2be4e"/>
      <path d="M1278.8,464.5v-55L1233,437Z" fill="#f8cc78"/>
      <path d="M1141.3,437V382l-45.8,27.5Z" fill="#e27b64"/>
      <path d="M1278.8,354.5v-55L1233,327Z" fill="#cec46e"/>
      <path d="M1187.2,299.5v55L1233,327Z" fill="#cec46e"/>
      <path d="M1187.4,354.5v55l45.8-27.5Z" fill="#e0ce86"/>
      <path d="M1233.2,382V327l-45.8,27.5Z" fill="#d0bd69"/>
      <path d="M1187.2,354.5v-55L1141.3,327Z" fill="#efaa4f"/>
      <path d="M1324.7,327V272l-45.9,27.5Z" fill="#f8cc78"/>
      <path d="M1141.3,327v55l45.9-27.5Z" fill="#eec573"/>
      <path d="M1278.8,299.5v-55L1233,272Z" fill="#efaa4f"/>
      <path d="M1003.8,354.8v55l45.9-27.5Z" fill="#db6d91"/>
      <path d="M1095.5,354.8v55l45.8-27.5Z" fill="#eec573"/>
      <path d="M1003.8,409.3v55.871l45.9-27.935Z" fill="#e094c1"/>
      <path d="M1095.5,409.8v-55l-45.8,27.5Z" fill="#ea86bd"/>
      <path d="M1324.7,217v55l45.8-27.5Z" fill="#f2be4e"/>
      <path d="M728.8,574.9v55l45.9-27.5Z" fill="#80b7a1"/>
      <path d="M728.8,574.3v-55L683,546.8Z" fill="#88b1b0"/>
      <path d="M683,602.4v55l45.8-27.5Z" fill="#7fd6d6"/>
      <path d="M683.3,711.6l-.1,55,45.9-27.5Z" fill="#5abab5"/>
      <path d="M1187.4,409.5v55l45.8-27.5Z" fill="#f3a94f"/>
      <path d="M1233.2,437V382l-45.8,27.5Z" fill="#f2be4e"/>
      <path d="M1370.5,299.5v-55L1324.7,272Z" fill="#f8cc78"/>
      <path d="M1095.5,299.3v55l45.8-27.5Z" fill="#efaa4f"/>
      <path d="M1141.3,326.8v-55l-45.8,27.5Z" fill="#eec573"/>
      <path d="M1187.2,299.5v-55L1141.3,272Z" fill="#eeeb79"/>
      <path d="M1187.2,244.5v55L1233,272Z" fill="#f2cf52"/>
      <path d="M1324.7,272V217l-45.9,27.5Z" fill="#f8cc78"/>
      <path d="M1370.5,244.5v-55L1324.7,217Z" fill="#f2cf52"/>
      <path d="M1324.7,162v55l45.8-27.5Z" fill="#eec573"/>
      <path d="M1324.7,876.8v-55l-45.9,27.5Z" fill="#5abab5"/>
      <path d="M1278.8,794.3v55l45.9-27.5Z" fill="#45a4ad"/>
      <path d="M1324.7,821.8v-55l-45.9,27.5Z" fill="#4ab19f"/>
      <path d="M1278.8,849.3v55l45.9-27.5Z" fill="#80bdba"/>
      <path d="M1233,821.8v55l45.8-27.5Z" fill="#84a568"/>
      <path d="M1233,766.8v55l45.8-27.5Z" fill="#7bb6a4"/>
      <path d="M1324.7,876.8v55l45.8-27.5Z" fill="#88c5c2"/>
      <path d="M1141.3,821.8v55l45.9-27.5Z" fill="#87bea1"/>
      <path d="M1233,876.8v-55l-45.8,27.5Z" fill="#b9b760"/>
      <path d="M1095.5,794.3v55l45.8-27.5Z" fill="#bcb25e"/>
      <path d="M1233,821.8v-55l-45.8,27.5Z" fill="#b89c5e"/>
      <path d="M1370.5,849.5v-55L1324.7,822Z" fill="#71aab6"/>
      <path d="M1187.2,849.3v-55l-45.9,27.5Z" fill="#cec46e"/>
      <path d="M1141.3,766.8v55l45.9-27.5Z" fill="#f2cf52"/>
      <path d="M1003.8,850.275V794.5L958,822.387Z" transform="translate(0 -0.775)" fill="#ea86bd"/>
      <path d="M1095.5,849.3v-55l-45.8,27.5Z" fill="#de80b3"/>
      <path d="M958,766.8v55l45.8-27.5Z" fill="#de80b3"/>
      <path d="M912.2,794.3v55L958,821.8Z" fill="#b388ad"/>
      <path d="M866.3,821.8v-55l-45.8,27.5Z" fill="#88b1b0"/>
      <path d="M958,436.8v-55l-45.8,27.5Z" fill="#e0ce86"/>
      <path d="M1003.8,270.8v55l45.9-27.5Z" fill="#e094c1"/>
      <path d="M728.8,794.5v55L774.7,822Z" fill="#7fd6d6"/>
      <path d="M1278.8,464.5v-55L1233,437Z" transform="translate(-137 -247)" fill="#f8cc78"/>
      <path d="M1324.7,382V327l-45.9,27.5Z" transform="translate(2557.5 489) rotate(180)" fill="#e27b64"/>
      <path d="M1141.3,711.8v-55l-45.8,27.5Z" transform="translate(-46 193)" fill="#e598b7"/>
    </g>
  </svg>
);

export default Background;