import LazyLoad from 'react-lazyload';
import React from 'react';
import styled, { keyframes } from 'styled-components';
import { fadeInUp } from 'react-animations';
import { useMediaQuery } from 'react-responsive';

const fadeInUpAnimation = keyframes`${fadeInUp}`;
const Page = styled.div`
  position: relative;
  
  width: 100%;
  font-family: Muli, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
    -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
   animation: 1s ${fadeInUpAnimation};
`;


const Content = styled.div`
  background-color: #74bda9;
  color: #061529;
  padding: 8vh 6vw;
  text-align: center;
  animation: 1s ${fadeInUpAnimation};
  font-size: 1.4em;
`;

const Heading = styled.span`
  font-size: 2.8em;
`;

const Systems = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    

`;
const System = styled.img`
  max-height: 80%;
  max-width: 80%;
`;

const SystemBox = styled.div`
  background-color: white;
  margin: 1vw; 
  border-radius: 8px;
  display: flex;
  width: ${props => props.isMobile ? '40vw' : '18vw'};
  height: ${props => props.isMobile ? 80 : 100}px;
  justify-content: center;
  align-items: center;
  box-shadow: 3px 3px rgba(102,167,149, 0.4)
`;

const SecureSystems = () => {

  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
  return <Page>

    <LazyLoad height={200} offset={0}>
      <>
        <Content>
          <Heading>
            Secure Systems
          </Heading>
          <p>
            Socrates application and system architecture are built to exceptionally high security standards and complies
            with many leading industry compliance regulators.

          </p>
          <p>
            Secure by design is our leading principle that affects all areas of our business and is something that we
            strongly adhere to. By utilising the vast wealth of internal security expertise and the latest enterprise
            grade security tools provided by industry experts e.g. AWS, NewRelic, AlertLogic, Hashicorp suite, etc,
            Socrates ensure that our platform is fully compliant and secure, 24/7
          </p>
          <Systems>
            <SystemBox isMobile={isMobile}>
              <System src={process.env.REACT_APP_S3_URL + '/images/system/aws.png'}/>
            </SystemBox>
            <SystemBox isMobile={isMobile}>
              <System src={process.env.REACT_APP_S3_URL + '/images/system/newrelic.png'}/>
            </SystemBox >
            <SystemBox isMobile={isMobile}>
              <System src={process.env.REACT_APP_S3_URL + '/images/system/sec1.png'}/>
            </SystemBox >
            <SystemBox isMobile={isMobile}>
              <System src={process.env.REACT_APP_S3_URL + '/images/system/alertlogic.png'}/>
            </SystemBox>


          </Systems>
        </Content>
      </>
    </LazyLoad>
  </Page>
}

export default SecureSystems
