import React from 'react';
import styled, { keyframes } from 'styled-components';
import { bounceInUp } from 'react-animations';
import Logos from '../Logos'
import Header from '../Header'
import Background from '../Background';
import { useMediaQuery } from 'react-responsive'
import logo from '../../assets/logo-lg.png';

const bounceInUpAnimation = keyframes`${bounceInUp}`;
const textMargin = 255;
const mobileTextMargin = 50;
const FirstPage = styled.div`
  position: relative;
  background: #061529;
  color: white;
  height:  ${props => props.isMobile ? 80 : 85}vh;
  font-weight: 300;
  width: 100vw;
  font-family: Muli, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
    -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
`;

const HeroTitle = styled.div`
  font-size:  ${props => props.isMobile ? 2.5 : 4.2}em;
  font-weight: 300;
  margin-left: ${props => props.isMobile ? mobileTextMargin : textMargin}px;
  margin-top: 8px;
`;

const HeroSubTitle = styled.div`
  font-size: 1em;
   margin-left: ${props => props.isMobile ? mobileTextMargin : textMargin}px;
   em {
    font-weight: bold;
    font-style: normal;
   }
`;

const HeroLogo = styled.div`
  position:absolute;
  width: 50%;
  top: -25px;
  left: -35px;
  background-image: url(${logo});
  background-size: contain;
  background-repeat: no-repeat;
  height:  ${props => (props.isMobile ? '155px;' : '300px;')};
`;

const StyledBackground = styled.div`
  svg {
    height: calc(80vh - 60px);
    max-width: ${props => (props.isMobile ? 80 : 50)}%;
    position: absolute;
    right: 0;
    top: 60px;
    opacity:  ${props => (props.isTablet ? 0.2 : 1)};
   }
`;


const HeroBlurb = styled.div`
    width: ${props => (props.isMobile ? 80 : 40)}%;
    padding-top: 45px;
    font-size: ${props => (props.isTablet ? '2em' : '3em')};
    margin-left: ${props => props.isMobile ? mobileTextMargin : textMargin}px;
    font-weight: 200;
    animation: 1s ${bounceInUpAnimation};
`;

const Home = () => {
  const isTablet = useMediaQuery({ query: '(max-width: 1224px)' });
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });
  return (
    <>
      <FirstPage isMobile={isMobile}>
        <StyledBackground
          isMobile={isMobile}
          isTablet={isTablet}
        >
          <Background/>
        </StyledBackground>
        {/*<MenuBar/>*/}
        <Header isMobile={isMobile}/>
        <HeroLogo isMobile={isMobile}/>
        <HeroTitle isMobile={isMobile}>
          Socrates Systems
        </HeroTitle>
        <HeroSubTitle isMobile={isMobile}>
          Developers of <b>robust, intuitive Insurance</b> Systems
        </HeroSubTitle>
        <HeroBlurb isTablet={isTablet}  isMobile={isMobile}>
          A digital engagement, distribution and underwriting platform for the <b>insurance</b> sector.
        </HeroBlurb>
        <Logos isMobile={isMobile}/>

      </FirstPage>
    </>
  )
};


export default Home;
