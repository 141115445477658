import React from 'react';
import styled from 'styled-components';
import { FaHome } from 'react-icons/fa';
import { Link } from 'react-router-dom';


const StyledMenuBar = styled.div`
  background:  ${props => (props.alt ? '#FFFFFF' : '#061529')};
  display: flex;
  flex-direction: ${props => (props.isMobile ? 'column' : 'row')};
  align-items: ${props => (props.isMobile ? 'end' : 'baseline')};
  justify-content: ${props => (props.isMobile ? 'space-evenly' : 'flex-end')};

  padding: ${props => (props.isMobile ? '5px' : ' 10px 20px 30px 20px')};

  height: 10vh;

`;

const HomeLink =styled(Link)`
  margin-right: ${props => (props.isMobile ? '3px' : '30px')};
  font-size: ${props => (props.isMobile ? '12px' : '16px')};

  
  margin-top: ${props => (props.isMobile ? '5px' : '20px')};
  color:  ${props => (props.alt ? '#061529' : '#FFFFFF')};
  &:hover {
    text-decoration: underline;
 }
`;

const HeaderLink = styled(Link)`
  margin-right: ${props => (props.isMobile ? '3px' : '30px')};
  font-size: ${props => (props.isMobile ? '12px' : '16px')};
  margin-top: ${props => (props.isMobile ? '2px' : '20px')};
  color:  ${props => (props.alt ? '#061529' : '#FFFFFF')};
  text-decoration: none;
  svg {
    margin-top: ${props => (props.isMobile ? '0' : '5px')};
  
  }
   &:hover {
    text-decoration: underline;
  
  }
`;

const MenuBar = ({ isMobile, alt }) => {
  return <StyledMenuBar alt={alt} isMobile={isMobile}>
    <HomeLink
      to={'/'}
      isMobile={isMobile}
      alt={alt}
    >
      <FaHome size={isMobile ? 16: 18}/>
    </HomeLink>

    <HeaderLink
      to={'/about-us'}
      isMobile={isMobile}
      alt={alt}
    >
      About Us
    </HeaderLink>
    <HeaderLink
      to={'/privacy'}
      isMobile={isMobile}
      alt={alt}
    >
      Privacy
    </HeaderLink>
    <HeaderLink
      to={'/contact'}
      isMobile={isMobile}
      alt={alt}
    >
      Contact
    </HeaderLink>
  </StyledMenuBar>;
};

const Header = ({ isMobile, alt }) => {
  return (
    <MenuBar isMobile={isMobile} alt={alt}/>
  );
};
export default Header;
