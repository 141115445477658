import React from 'react';
import Main from './pages/Main';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from 'react-router-dom'
import AboutUs from './pages/AboutUs';
import Contact from './pages/Contact';
import Privacy from './pages/Privacy';


function App() {
  return (
    <div className="App">
      <Router>
        <Switch>
          <Route path="/" exact={true}>
            <Main/>
          </Route>
          <Route path="/about-us" >
            <AboutUs/>
          </Route>
          <Route path="/contact">
            <Contact/>
          </Route>
          <Route path="/privacy">
            <Privacy/>
          </Route>
        </Switch>

      </Router>
    </div>
  );
}

export default App;
