import React, { useEffect } from 'react';
import styled, { createGlobalStyle } from 'styled-components';
import Header from '../components/Header';
import { useMediaQuery } from 'react-responsive';
import logo from '../assets/logo-lg.png';
import Footer from '../components/Footer';
import { scrollToTop } from '../components/ScrollHandler';

const Page = styled.div`
  position: relative;
  color: #061529;
  font-weight: 300;
  width: 100vw;
  //height: 100vh;
  min-height: calc(100vh - ${props => props.isMobile ? 100 : 150}px);
  font-family: Muli, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1.2rem;
  margin-bottom: 80px;
`;

const HeroLogo = styled.div`
  position: absolute;
  width: 50%;
  top: -25px;
  left: -35px;
  background-image: url(${logo});
  background-size: contain;
  background-repeat: no-repeat;
  height: ${props => (props.isMobile ? '200px;' : '300px;')};
`;

const Title = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

`;


const TitleText = styled.div`
  font-size: ${props => (props.isMobile ? '3rem;' : '4rem')};
  margin-left: 3vw;

`;
const SubTitle = styled.div`
  font-weight: bold;
  margin-top: 10px;
  margin-left: 10px;
  text-indent: 0;
`;

const Emphasis = styled.span`
  font-weight: bold;;
`;


const Paragraph = styled.p`
  color: #061529;
  margin-left: 10vw;
  margin-right: 10vw;
  font-weight: normal;

`;
const ListParagraph = styled.p`
  color: #061529;
  text-indent: 0;
  margin-left: 10px;

  font-weight: normal;
`;

const Heading = styled.div`
  font-weight: bold;

  margin-bottom: 10px;
`;


const ListTitle = styled.div`
  text-transform: uppercase;
  font-weight: bold;
  text-indent: 10px
`;

const ListItem = styled.li`
  text-indent: 10px;
`;

const OrderedList = styled.ol`
  padding-inline-start: 20px;
  font-weight: bold;
`;

const UnorderedList = styled.ul`
  font-weight: normal;
  padding-inline-start: 20px;

  li {
    text-indent: 10px;
    margin-left: 20px;
  }
`;


const AnchorLink = styled.a`

  text-decoration: none;
  font-style: italic;
`;
const Table = styled.table`
  border-collapse: collapse;
  margin: 10px;

  th, td {
    border: 1px solid black;
    width: 33%;
    text-align: left;
    padding: 5px;
  }

  td {

    font-weight: normal;
    vertical-align: baseline;
  }
`;
const TableCellContent = styled.div`
  display: flex;
  flex-direction: column;
`;

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    width: 100vw;
  }
`;

const TableListItem = styled.span`
  margin-bottom: 5px;
`;

const Privacy = () => {
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

  useEffect(() => {
    scrollToTop()
  },[])
  return (
    <>
      <GlobalStyle/>
      <Page isMobile={isMobile}>
        <Header isMobile={isMobile} alt/>
        <HeroLogo isMobile={isMobile}/>
        <Title>
          <TitleText isMobile={isMobile}>
            Privacy
          </TitleText>
        </Title>


        <Paragraph>
          <Heading>Introduction</Heading>
          Welcome to the Socrates System's privacy policy.
        </Paragraph>
        <Paragraph>
          Socrates Systems Ltd, respects your privacy and is committed to protecting your personal data. This privacy
          policy will inform you as to how we look after your personal data when you visit our website (regardless of
          where you visit it from) and tell you about your privacy rights and how the law protects you.
        </Paragraph>
        {/*<Paragraph>*/}
        {/*  This privacy policy is provided in a layered format so you can click through to the specific areas set out below. Please also use the Glossary to understand the meaning of some of the terms used in this privacy policy.*/}
        {/*</Paragraph>*/}
        <Paragraph>
          <OrderedList>
            <ListItem>
              <ListTitle>IMPORTANT INFORMATION AND WHO WE ARE</ListTitle>
              <SubTitle>Purpose of this privacy policy</SubTitle>
              <ListParagraph>
                This privacy policy aims to give you information on how Socrates Systems Ltd processes your personal
                data through your use of this website, including any data you may provide through this website when you
                purchase a product or service.
              </ListParagraph>
              <ListParagraph>
                This website is not intended for children.
              </ListParagraph>
              <ListParagraph>
                It is important that you read this privacy policy together with any other privacy policy or fair
                processing policy we may provide on specific occasions when we are processing personal data about you so
                that you are fully aware of how and why we are using your data. This privacy policy supplements other
                notices and privacy policies and is not intended to override them.
              </ListParagraph>
              <SubTitle>Controller</SubTitle>
              <ListParagraph>
                Socrates Systems Ltd, for the most part, acts a Processor only. Socrates will only act and process
                Personal Data in accordance with the documented instruction from the Customer. (collectively referred to
                as “Socrates”, "we", "us" or "our" in this privacy policy).
              </ListParagraph>
              <ListParagraph>
                We have appointed a data protection officer (DPO) who is responsible for overseeing questions in
                relation to this privacy policy. If you have any questions about this privacy policy, including any
                requests to exercise your legal rights, please contact the DPO using the details set out below.
              </ListParagraph>
              <SubTitle>Contact details</SubTitle>
              <ListParagraph>
                If you have any questions about this privacy policy or our privacy practices, please contact our DPO in
                the following ways:
              </ListParagraph>
              <ListParagraph>
                Full name of legal entity: Socrates Systems Ltd
              </ListParagraph>
              <ListParagraph>
                Email address: dpo@socrates.systems
              </ListParagraph>
              <ListParagraph>
                Postal address: 28 Castle Street, Hertford, Hertfordshire, SG14 1HH.
              </ListParagraph>
              <ListParagraph>
                Telephone number: 03332412244
              </ListParagraph>
              <ListParagraph>
                You have the right to make a complaint at any time to the Information Commissioner's Office (ICO), the
                UK regulator for data protection issues (www.ico.org.uk). We would, however, appreciate the chance to
                deal with your concerns before you approach the ICO so please contact us in the first instance.
              </ListParagraph>
              <SubTitle>Changes to the privacy policy and your duty to inform us of changes</SubTitle>
              <ListParagraph>
                We keep our privacy policy under regular review. This version was last updated on 11th July 2023.
              </ListParagraph>
              <ListParagraph>
                It is important that the personal data we hold about you is accurate and current. Please keep us
                informed if your personal data changes during your relationship with us.
              </ListParagraph>
              <SubTitle>Third-party links</SubTitle>
              <ListParagraph>
                This website may include links to third-party websites, plug-ins and applications. Clicking on those
                links or enabling those connections may allow third parties to share data about you. We do not control
                these third-party websites and are not responsible for their privacy statements. When you leave our
                website, we encourage you to read the privacy policy of every website you visit.
              </ListParagraph>
            </ListItem>
            <ListItem>
              <ListTitle>THE DATA WE PROCESS ABOUT YOU</ListTitle>
              <ListParagraph>
                Personal data, or personal information, means any information about an individual from which that person
                can be identified. It does not include data where the identity has been removed (anonymous data).
              </ListParagraph>
              <ListParagraph>
                We may process, store and transfer different kinds of personal data about you which we have grouped
                together as follows:
              </ListParagraph>
              <UnorderedList>
                <li>
                  <ListParagraph>
                    <Emphasis>Identity Data</Emphasis> includes first name, maiden name, last name,
                    username or similar
                    identifier, marital status, title, date of birth and gender.</ListParagraph>
                </li>
                <li>
                  <ListParagraph>
                    <Emphasis>Contact Data </Emphasis> includes billing address, delivery address, email
                    address and
                    telephone numbers.</ListParagraph>
                </li>
                <li>
                  <ListParagraph>
                    <Emphasis>Financial Data </Emphasis> includes bank account and payment card details.
                  </ListParagraph>
                </li>
                <li>
                  <ListParagraph>
                    <Emphasis>Transaction Data </Emphasis> includes details about payments to and from
                    you and other
                    details of products and services you have purchased from us.
                  </ListParagraph>
                </li>
                <li>
                  <ListParagraph>
                    <Emphasis>Technical Data </Emphasis> includes internet protocol (IP) address, your
                    login data,
                    browser type and version, time zone setting and location, browser plug-in types and versions,
                    operating system and platform, and other technology on the devices you use to access this
                    website.
                  </ListParagraph>
                </li>
                <li>
                  <ListParagraph>
                    <Emphasis>Profile Data </Emphasis> includes your username and password, purchases or
                    orders made
                    by you, your interests, preferences, feedback and survey responses.
                  </ListParagraph>
                </li>
                <li>
                  <ListParagraph>
                    <Emphasis>Usage Data </Emphasis> includes information about how you use our website,
                    products and
                    services.
                  </ListParagraph>
                </li>
                <li>
                  <ListParagraph>
                    <Emphasis>Marketing and Communications Data </Emphasis> includes your preferences in
                    receiving
                    marketing from us and our third parties and your communication preferences.
                  </ListParagraph>
                </li>
                <li>
                  <ListParagraph>
                    <Emphasis>Special Data </Emphasis> includes information about your health but
                    excluding details
                    about your race or ethnicity, religious or philosophical beliefs, sex life, sexual orientation,
                    political opinions, trade union membership, and genetic and biometric data. We do not process any
                    information about criminal convictions and offences.
                  </ListParagraph>
                </li>
              </UnorderedList>


              <ListParagraph>
                We also process <Emphasis>Aggregated Data</Emphasis> such as statistical or demographic data for any
                purpose. Aggregated Data could be derived from your personal data but is not considered personal data in
                law as this data will not directly or indirectly reveal your identity. For example, we may aggregate
                your
                Usage Data to calculate the percentage of users accessing a specific website feature. However, if we
                combine or connect Aggregated Data with your personal data so that it can directly or indirectly
                identify
                you, we treat the combined data as personal data which will be used in accordance with this privacy
                policy.
              </ListParagraph>

              <SubTitle>If you fail to provide personal data</SubTitle>
              <ListParagraph>
                Where we need to process personal data by law, or under the terms of a contract we have with you, and
                you fail to provide that data when requested, we may not be able to perform the contract we have or are
                trying to enter into with you (for example, to provide you with goods or services). In this case, we may
                have to cancel a product or service you have with us but we will notify you if this is the case at the
                time.
              </ListParagraph>
            </ListItem>
            <ListItem>

              <ListTitle>HOW IS YOUR PERSONAL DATA PROCCESSED?</ListTitle>
              <ListParagraph>
                <UnorderedList>
                  <li>
                    <ListParagraph>
                      Socrates will only act and process Personal Data in accordance with the documented instruction
                      from
                      the Customer, unless required by law to act without such instruction. The instruction at the time
                      of
                      entering into an agreement with the Customer is that Socrates may only process the Personal Data
                      with
                      the purpose of delivering the services as described in the agreement. The Customer is responsible
                      for
                      ensuring that all individuals who provide instructions to Socrates are authorised to do so.
                    </ListParagraph>
                  </li>
                  <li>
                    <ListParagraph>
                      To provide the services described within the agreement with the Customer, Socrates are authorised
                      to
                      engage third-parties to process Personal Data (“Sub-Processors”) without obtaining any further
                      written, specific authorisation from the Customer, provided that Socrates notifies the Customer in
                      writing about the identity of a potential Sub-Processor before any agreements are made with the
                      relevant Sub-Processor and before the relevant Sub-Processor processes any Personal Data.
                    </ListParagraph>
                  </li>
                  <li>
                    <ListParagraph>
                      Socrates at the time of entering in an agreement with the Customer, uses the following
                      Sub-Processors (engaged by Socrates):
                      <UnorderedList>
                        <li><ListParagraph>AWS</ListParagraph></li>
                        <li><ListParagraph>Alert Logic</ListParagraph></li>
                        <li><ListParagraph>SendGrid</ListParagraph></li>
                        <li><ListParagraph>TextMarketer</ListParagraph></li>
                      </UnorderedList>
                    </ListParagraph>
                  </li>
                  <li>
                    <ListParagraph>Please refer to the Data Protection Impact Assessment (available upon request:
                      dpo@socrates.systems)
                      to better understand the arrangement between Socrates and each Sub-Processor engaged by Socrates.
                    </ListParagraph>
                  </li>


                </UnorderedList>
              </ListParagraph>
            </ListItem>
            <ListItem>
              <ListTitle>HOW WE USE YOUR PERSONAL DATA</ListTitle>
              <ListParagraph>
                We will only use your personal data when the law allows us to. Most commonly, we will use your personal
                data in the following circumstances:
              </ListParagraph>
              <UnorderedList>
                <li>
                  <ListParagraph>
                    Where we need to perform the contract we are about to enter into or have entered into with you.
                  </ListParagraph>
                </li>
                <li>
                  <ListParagraph>
                    Where it is necessary for our legitimate interests (or those of a third party) and your interests
                    and fundamental rights do not override those interests.
                  </ListParagraph>
                </li>
                <li>
                  <ListParagraph>
                    Where we need to comply with a legal obligation.
                  </ListParagraph>
                </li>
              </UnorderedList>
              <ListParagraph>
                Please review the Glossary below, to find out more about the types of lawful basis that we will rely on
                to process your personal data.
              </ListParagraph>
              <ListParagraph>
                Generally, we do not rely on consent as a legal basis for processing your personal data although we will
                get your consent before sending third party direct marketing communications to you via email or text
                message. You have the right to withdraw consent to marketing at any time by contacting us.
              </ListParagraph>
              <SubTitle>
                Purposes for which we will use your personal data
              </SubTitle>
              <ListParagraph>
                We have set out below, in a table format, a description of all the ways we plan to use your personal
                data, and which of the legal bases we rely on to do so. We have also identified what our legitimate
                interests are where appropriate.
              </ListParagraph>
              <ListParagraph>
                Note that we may process your personal data for more than one lawful ground depending on the specific
                purpose for which we are using your data. Please contact us if you need details about the specific legal
                ground we are relying on to process your personal data where more than one ground has been set out in
                the table below.
              </ListParagraph>
              <Table>
                <tr>
                  <th>Purpose/Activity</th>
                  <th>Type of data</th>
                  <th>Lawful basis for processing including basis of legitimate interest</th>
                </tr>
                <tr>
                  <td>To register you as a new customer</td>
                  <td>
                    <TableCellContent>
                      <TableListItem>(a) Identity</TableListItem>
                      <TableListItem>(b) Contact</TableListItem>
                    </TableCellContent>
                  </td>
                  <td>Performance of a contract with you</td>
                </tr>

                <tr>
                  <td>
                    To process and deliver your order including:
                    <TableCellContent>
                      <TableListItem>(a) Manage payments, fees and charges</TableListItem>
                      <TableListItem>(b) Collect and recover money owed to us</TableListItem>

                    </TableCellContent></td>
                  <td>
                    <TableCellContent>
                      <TableListItem>(a) Identity</TableListItem>
                      <TableListItem>(b) Contact</TableListItem>
                      <TableListItem>(c) Financial</TableListItem>
                      <TableListItem>(d) Transaction</TableListItem>
                      <TableListItem>(e) Marketing and Communications</TableListItem>
                    </TableCellContent>
                  </td>
                  <td>
                    <TableCellContent>
                      <TableListItem>(a) Performance of a contract with you</TableListItem>
                      <TableListItem>(b) Necessary for our legitimate interests (to recover debts due to
                        us)</TableListItem>
                    </TableCellContent>
                  </td>
                </tr>
                <tr>
                  <td>
                    To manage our relationship with you which will include:
                  </td>
                  <td>
                    <TableCellContent>
                      <TableListItem>(a) Identity</TableListItem>
                      <TableListItem>(b) Contact</TableListItem>
                      <TableListItem>(c) Profile</TableListItem>
                      <TableListItem>(d) Marketing and Communications</TableListItem>
                    </TableCellContent>
                  </td>
                  <td>
                    <TableCellContent>
                      <TableListItem>(a) Performance of a contract with you</TableListItem>
                      <TableListItem>(b) Necessary to comply with a legal obligation</TableListItem>
                      <TableListItem>(c) Necessary for our legitimate interests (to keep our records updated and to
                        study how customers use our products/services)</TableListItem>
                    </TableCellContent>
                  </td>
                </tr>
                <tr>
                  <td>
                    To enable you to partake in a prize draw, competition or complete a survey
                  </td>
                  <td>
                    <TableCellContent>
                      <TableListItem>(a) Identity</TableListItem>
                      <TableListItem>(b) Contact</TableListItem>
                      <TableListItem>(c) Profile</TableListItem>
                      <TableListItem>(d) Usage</TableListItem>
                      <TableListItem>(e) Marketing and Communications</TableListItem>
                    </TableCellContent>
                  </td>
                  <td><TableCellContent>
                    <TableListItem>(a) Performance of a contract with you</TableListItem>
                    <TableListItem>(b) Necessary for our legitimate interests (to study how customers use our
                      products/services, to develop them and grow our business)</TableListItem>
                  </TableCellContent>
                  </td>
                </tr>
                <tr>
                  <td>
                    To administer and protect our business and this website (including troubleshooting, data analysis,
                    testing, system maintenance, support, reporting and hosting of data)
                  </td>
                  <td>
                    <TableCellContent>
                      <TableListItem>(a) Identity</TableListItem>
                      <TableListItem>(b) Contact</TableListItem>
                      <TableListItem>(c) Technical</TableListItem>

                    </TableCellContent>
                  </td>
                  <td><TableCellContent>
                    <TableListItem>(a) Necessary for our legitimate interests (for running our business, provision of
                      administration and IT services, network security, to prevent fraud and in the context of a
                      business reorganisation or group restructuring exercise)</TableListItem>
                    <TableListItem>(b) Necessary to comply with a legal obligation</TableListItem>

                  </TableCellContent>
                  </td>
                </tr>
                <tr>
                  <td>
                    To deliver relevant website content and advertisements to you and measure or understand the
                    effectiveness of the advertising we serve to you
                  </td>
                  <td>
                    <TableCellContent>
                      <TableListItem>(a) Identity</TableListItem>
                      <TableListItem>(b) Contact</TableListItem>
                      <TableListItem>(c) Profile</TableListItem>
                      <TableListItem>(d) Usage</TableListItem>
                      <TableListItem>(e) Marketing and Communications</TableListItem>
                      <TableListItem>(f) Technical</TableListItem>
                    </TableCellContent>
                  </td>
                  <td>
                    Necessary for our legitimate interests (to study how customers use our products/services, to develop
                    them, to grow our business and to inform our marketing strategy)

                  </td>
                </tr>
                <tr>
                  <td>
                    To use data analytics to improve our website, products/services, marketing, customer relationships
                    and experiences
                  </td>
                  <td>
                    <TableCellContent>
                      <TableListItem>(a) Technical</TableListItem>
                      <TableListItem>(b) Usage</TableListItem>
                    </TableCellContent>
                  </td>
                  <td>
                    Necessary for our legitimate interests (to define types of customers for our products and services,
                    to keep our website updated and relevant, to develop our business and to inform our marketing
                    strategy)
                  </td>
                </tr>
                <tr>
                  <td>
                    To make suggestions and recommendations to you about goods or services that may be of interest to
                    you
                  </td>
                  <td>
                    <TableCellContent>
                      <TableListItem>(a) Identity</TableListItem>
                      <TableListItem>(b) Contact</TableListItem>
                      <TableListItem>(c) Technical</TableListItem>
                      <TableListItem>(d) Usage</TableListItem>
                      <TableListItem>(e) Profile</TableListItem>
                      <TableListItem>(f) Marketing and Communications</TableListItem>
                    </TableCellContent>
                  </td>
                  <td>
                    Necessary for our legitimate interests (to develop our products/services and grow our business)
                  </td>
                </tr>


              </Table>
              <SubTitle>
                Marketing
              </SubTitle>
              <ListParagraph>
                We strive to provide you with choices regarding certain personal data uses, particularly around
                marketing and advertising.
              </ListParagraph>
              <SubTitle>
                Promotional offers from us
              </SubTitle>
              <ListParagraph>
                We may use your Identity, Contact, Technical, Usage and Profile Data to form a view on what we think you
                may want or need, or what may be of interest to you. This is how we decide which products, services and
                offers may be relevant for you (we call this marketing).
              </ListParagraph>
              <ListParagraph>
                You will receive marketing communications from us if you have requested information from us or purchased
                products or services from us and you have not opted out of receiving that marketing.
              </ListParagraph>
              <SubTitle>
                Opting out
              </SubTitle>
              <ListParagraph>
                You can ask us or third parties to stop sending you marketing messages at any time by following the
                opt-out links on any marketing message sent to you.
              </ListParagraph>
              <ListParagraph>
                Where you opt out of receiving these marketing messages, this will not apply to personal data provided
                to us as a result of a product/service purchase, warranty registration, product/service experience or
                other transactions.
              </ListParagraph>
              <SubTitle>
                Cookies
              </SubTitle>
              <ListParagraph>
                You can set your browser to refuse all or some browser cookies, or to alert you when websites set or
                access cookies. If you disable or refuse cookies, please note that some parts of this website may become
                inaccessible or not function properly.
              </ListParagraph>
              <SubTitle>
                Change of purpose
              </SubTitle>
              <ListParagraph>
                We will only use your personal data for the purposes for which we process it, unless we reasonably
                consider that we need to use it for another reason and that reason is compatible with the original
                purpose. If you wish to get an explanation as to how the processing for the new purpose is compatible
                with the original purpose, please contact us.
              </ListParagraph>
              <ListParagraph>
                If we need to use your personal data for an unrelated purpose, we will notify you and we will explain
                the legal basis which allows us to do so.
              </ListParagraph>
              <ListParagraph>
                Please note that we may process your personal data without your knowledge or consent, in compliance with
                the above rules, where this is required or permitted by law.
              </ListParagraph>
            </ListItem>
            <ListItem>
              <ListTitle>DISCLOSURES OF YOUR PERSONAL DATA</ListTitle>
              <ListParagraph>
                We may share your personal data with the parties set out below for the purposes set out in the table
                above.
              </ListParagraph>
              <UnorderedList>
                <li>
                  <ListParagraph>
                    Internal Third Parties as set out in the Glossary.
                  </ListParagraph>
                </li>
                <li>
                  <ListParagraph>
                    External Third Parties as set out in the Glossary.
                  </ListParagraph>
                </li>
                <li>
                  <ListParagraph>
                    Third parties to whom we may choose to sell, transfer or merge parts of our business
                    or our
                    assets. Alternatively, we may seek to acquire other businesses or merge with them. If a change
                    happens
                    to our business, then the new owners may use your personal data in the same way as set out in this
                    privacy policy.
                  </ListParagraph>
                </li>

              </UnorderedList>
              <ListParagraph>
                We require all third parties to respect the security of your personal data and to treat it in accordance
                with the law. We do not allow our third-party service providers to use your personal data for their own
                purposes and only permit them to process your personal data for specified purposes and in accordance
                with our instructions.
              </ListParagraph>

            </ListItem>
            <ListItem>
              <ListTitle>INTERNATIONAL TRANSFERS</ListTitle>
              <ListParagraph>
                We share your personal data within the SendGrid Inc, a Delaware corporation, having its primary offices
                at 1401 Walnut St, Suite 500, Boulder, CO 90302. This will involve transferring your data outside the
                UK.
              </ListParagraph>
              <ListParagraph>
                Some of our external third parties are based outside the UK so their processing of your personal data
                will involve a transfer of data outside the UK.
              </ListParagraph>
              <ListParagraph>
                Please contact us if you want further information on the specific mechanism used by us when transferring
                your personal data out of the UK.
              </ListParagraph>
            </ListItem>
            <ListItem>
              <ListTitle> DATA SECURITY</ListTitle>
              <ListParagraph>
                We have put in place appropriate security measures to prevent your personal data from being accidentally
                lost, used or accessed in an unauthorised way, altered or disclosed. In addition, we limit access to
                your personal data to those employees, agents, contractors and other third parties who have a business
                need to know. They will only process your personal data on our instructions and they are subject to a
                duty of confidentiality.
              </ListParagraph>
              <ListParagraph>
                We have put in place procedures to deal with any suspected personal data breach and will notify you and
                any applicable regulator of a breach where we are legally required to do so..
              </ListParagraph>
            </ListItem>
            <ListItem>
              <ListTitle>DATA RETENTION</ListTitle>
              <SubTitle>How long will you use my personal data for?</SubTitle>
              <ListParagraph>
                We will only retain your personal data for as long as reasonably necessary to fulfil the purposes we
                process it for, including for the purposes of satisfying any legal, regulatory, tax, accounting or
                reporting requirements. We may retain your personal data for a longer period in the event of a complaint
                or if we reasonably believe there is a prospect of litigation in respect to our relationship with you.
              </ListParagraph>
              <ListParagraph>
                To determine the appropriate retention period for personal data, we consider the amount, nature and
                sensitivity of the personal data, the potential risk of harm from unauthorised use or disclosure of your
                personal data, the purposes for which we process your personal data and whether we can achieve those
                purposes through other means, and the applicable legal, regulatory, tax, accounting or other
                requirements.
              </ListParagraph>
              <ListParagraph>
                By law we have to keep basic information about our customers (including Contact, Identity, Financial and
                Transaction Data) for six years after they cease being customers for tax purposes.
              </ListParagraph>
              <ListParagraph>
                In some circumstances you can ask us to delete your data: see ‘Your legal rights’ below for further
                information.
              </ListParagraph>
              <ListParagraph>
                In some circumstances we will anonymise your personal data (so that it can no longer be associated with
                you) for research or statistical purposes, in which case we may use this information indefinitely
                without further notice to you.
              </ListParagraph>
            </ListItem>
            <ListItem>
              <ListTitle>YOUR LEGAL RIGHTS</ListTitle>

              <ListParagraph>
                Under certain circumstances, you have rights under data protection laws in relation to your personal
                data. Please click on the links below to find out more about these rights:
              </ListParagraph>
              <UnorderedList>
                <li><AnchorLink href={"#request-access"}><ListParagraph>Request access to your personal
                  data.</ListParagraph></AnchorLink>
                </li>
                <li><AnchorLink href={"#request-correction"}><ListParagraph>Request correction of your personal
                  data</ListParagraph></AnchorLink></li>
                <li><AnchorLink href={"#request-erasure"}><ListParagraph>Request erasure of your personal
                  data.</ListParagraph></AnchorLink></li>
                <li><AnchorLink href={"#object-processing"}><ListParagraph>Object to processing of your personal
                  data.</ListParagraph></AnchorLink></li>
                <li><AnchorLink href={"#restrict-processing"}><ListParagraph>Request restriction of processing your
                  personal data.</ListParagraph></AnchorLink></li>
                <li><AnchorLink href={"#request-transfer"}><ListParagraph>Request transfer of your personal
                  data.</ListParagraph></AnchorLink></li>
                <li><AnchorLink href={"#withdraw-consent"}><ListParagraph>Right to withdraw
                  consent.</ListParagraph></AnchorLink></li>
              </UnorderedList>
              <ListParagraph>
                If you wish to exercise any of the rights set out above, please contact the DPO.
              </ListParagraph>
              <SubTitle>No fee usually required</SubTitle>
              <ListParagraph>
                You will not have to pay a fee to access your personal data (or to exercise any of the other rights).
                However, we may charge a reasonable fee if your request is clearly unfounded, repetitive or excessive.
                Alternatively, we could refuse to comply with your request in these circumstances.
              </ListParagraph>
              <SubTitle>What we may need from you</SubTitle>
              <ListParagraph>
                We may need to request specific information from you to help us confirm your identity and ensure your
                right to access your personal data (or to exercise any of your other rights). This is a security measure
                to ensure that personal data is not disclosed to any person who has no right to receive it. We may also
                contact you to ask you for further information in relation to your request to speed up our response.
              </ListParagraph>
              <SubTitle>Time limit to respond</SubTitle>
              <ListParagraph/>
            </ListItem>
            <ListItem>
              <ListTitle>TIME LIMIT TO RESPOND</ListTitle>
              <ListParagraph/>
            </ListItem>
            <ListItem>
              <ListTitle>GLOSSARY</ListTitle>
              <SubTitle>LAWFUL BASIS</SubTitle>
              <ListParagraph>

              </ListParagraph>
              <ListParagraph>
                <Emphasis>Controller</Emphasis> means ‘the Customer’, who determined the purposes and means of
                processing
                personal data.
              </ListParagraph>
              <ListParagraph>
                <Emphasis>Legitimate Interest </Emphasis> means the interest of our business in conducting and managing
                our business to enable us to give you the best service/product and the best and most secure experience.
                We
                make sure we consider and balance any potential impact on you (both positive and negative) and your
                rights
                before we process your personal data for our legitimate interests. We do not use your personal data for
                activities where our interests are overridden by the impact on you (unless we have your consent or are
                otherwise required or permitted to by law). You can obtain further information about how we assess our
                legitimate interests against any potential impact on you in respect of specific activities by contacting
                us.
              </ListParagraph>
              <ListParagraph>
                <Emphasis>Performance of Contract </Emphasis> means processing your data where it is necessary for the
                performance of a contract to which you are a party or to take steps at your request before entering into
                such a contract.
              </ListParagraph>
              <ListParagraph>
                <Emphasis>Processor </Emphasis> means ‘Socrates’, who is responsible for processing personal data on
                behalf of the ‘Controller’.
              </ListParagraph>
              <ListParagraph>
                <Emphasis>Comply with a legal obligation </Emphasis>means processing your personal data where it is
                necessary for compliance with a legal obligation that we are subject to.
              </ListParagraph>
              <SubTitle>THIRD PARTIES</SubTitle>
              <SubTitle>External Third Parties</SubTitle>
              <UnorderedList>
                <li><ListParagraph>
                  Service providers acting as Sub-Processors based in the United Kingdom and United States who
                  provide
                  IT, system administration and marketing services.
                </ListParagraph>
                </li>
                <li>
                  <ListParagraph>
                    Professional advisers acting as processors including lawyers, bankers, auditors and insurers based
                    in the United Kingdom and worldwide who provide consultancy, banking, legal, insurance and
                    accounting
                    services.
                  </ListParagraph>
                </li>
                <li>
                  <ListParagraph>
                    HM Revenue & Customs, regulators and other authorities acting as processors based in the United
                    Kingdom who require reporting of processing activities in certain circumstances.
                  </ListParagraph>
                </li>

              </UnorderedList>
              <SubTitle>YOUR LEGAL RIGHTS</SubTitle>
              <ListParagraph>
                You have the right to:
              </ListParagraph>
              <ListParagraph id="request-access">
                <Emphasis>Request access </Emphasis>to your personal data (commonly known as a "data subject access
                request"). This enables you to receive a copy of the personal data we hold about you and to check that
                we are lawfully processing it.
              </ListParagraph>
              <ListParagraph id="request-correction">
                <Emphasis>Request correction </Emphasis>of the personal data that we hold about you. This enables you to
                have any incomplete or inaccurate data we hold about you corrected, though we may need to verify the
                accuracy of the new data you provide to us.
              </ListParagraph>


              <ListParagraph id="request-erasure">
                <Emphasis>Request erasure </Emphasis>of your personal data. This enables you to ask us to delete or
                remove personal data where there is no good reason for us continuing to process it. You also have the
                right to ask us to delete or remove your personal data where you have successfully exercised your right
                to object to processing (see below), where we may have processed your information unlawfully or where we
                are required to erase your personal data to comply with local law. Note, however, that we may not always
                be able to comply with your request of erasure for specific legal reasons which will be notified to you,
                if applicable, at the time of your request.
              </ListParagraph>

              <ListParagraph id="object-processing">
                <Emphasis>Object to processing </Emphasis>of your personal data where we are relying on a legitimate
                interest (or those of a third party) and there is something about your particular situation which makes
                you want to object to processing on this ground as you feel it impacts on your fundamental rights and
                freedoms. You also have the right to object where we are processing your personal data for direct
                marketing purposes. In some cases, we may demonstrate that we have compelling legitimate grounds to
                process your information which override your rights and freedoms.
              </ListParagraph>
              <ListParagraph id="request-restriction">
                <Emphasis>Request restriction of processing </Emphasis>of your personal data. This enables you to ask us
                to suspend the processing of your personal data in the following scenarios:
              </ListParagraph>
              <UnorderedList>
                <li>
                  <ListParagraph>
                    If you want us to establish the data's accuracy.
                  </ListParagraph>
                </li>
                <li>
                  <ListParagraph>
                    Where our use of the data is unlawful but you do not want us to erase it.
                  </ListParagraph>
                </li>
                <li>
                  <ListParagraph>
                    Where you need us to hold the data even if we no longer require it as you need it to establish, exercise or defend legal claims.
                  </ListParagraph>
                </li>
                <li>
                  <ListParagraph>
                    You have objected to our use of your data but we need to verify whether we have overriding
                    legitimate grounds to use it.
                  </ListParagraph>
                </li>

              </UnorderedList>
              <ListParagraph id="request-transfer">
                <Emphasis>Request the transfer </Emphasis>transfer of your personal data to you or to a third party. We
                will provide to you, or a third party you have chosen, your personal data in a structured, commonly
                used, machine-readable format. Note that this right only applies to automated information which you
                initially provided consent for us to use or where we used the information to perform a contract with
                you.
              </ListParagraph>
              <ListParagraph id="withdraw-consent">
                <Emphasis>Withdraw consent at any time </Emphasis>where we are relying on consent to process your
                personal data. However, this will not affect the lawfulness of any processing carried out before you
                withdraw your consent. If you withdraw your consent, we may not be able to provide certain products or
                services to you. We will advise you if this is the case at the time you withdraw your consent.
              </ListParagraph>
            </ListItem>

          </OrderedList>
        </Paragraph>
      </Page>

      <Footer isMobile={isMobile}/>

    </>
  );
};

export default Privacy;
