import React, {  useState } from 'react';
import VisibilitySensor from "react-visibility-sensor";
const ScrollHandler = ({children}) => {
  const [ animate, setAnimate ] = useState();



  const isScrolledIntoView = (isVisible) =>
  {
    setAnimate(isVisible);
  };



  return (
    <VisibilitySensor onChange={isScrolledIntoView}>

      {React.cloneElement(children, { animate: animate })}
    </VisibilitySensor>
  )
};


export const scrollToTop = () => {
  window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
};



export default ScrollHandler;