import React from 'react';
import styled, { keyframes } from 'styled-components';
import { fadeInUp } from 'react-animations';
import { useMediaQuery } from 'react-responsive';

const NUMBER_LOGOS = 8;
const SLIDE_WIDTH = 200;
const MOBILE_SLIDE_WIDTH = 180;
const fadeInUpAnimation = keyframes`${fadeInUp}`;

const scroll = keyframes`
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(calc(-${SLIDE_WIDTH}px * ${NUMBER_LOGOS}));
    transform: translateX(calc(-${SLIDE_WIDTH}px *  ${NUMBER_LOGOS}));
  }
`;

const scrollMobile = keyframes`
  0% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  100% {
    -webkit-transform: translateX(calc(-${MOBILE_SLIDE_WIDTH}px * ${NUMBER_LOGOS}));
    transform: translateX(calc(-${MOBILE_SLIDE_WIDTH}px *  ${NUMBER_LOGOS}));
  }
`;

const SlideTrack = styled.div`
  -webkit-animation: ${props => props.isMobile ? scrollMobile : scroll} 40s linear infinite;
  animation: ${props => props.isMobile ? scrollMobile : scroll} 40s linear infinite;
  display: flex;
  width: calc(${props => props.isMobile ? MOBILE_SLIDE_WIDTH : SLIDE_WIDTH}px * ${NUMBER_LOGOS}) ;
`;

const Slide = styled.div`
  height: 12vh;
  min-width:  ${props => props.isMobile ? MOBILE_SLIDE_WIDTH : SLIDE_WIDTH}px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Slider = styled.div`
    height: 8vh;
    margin: auto;
    overflow: hidden;
    position: relative;
    width: 100%;
    z-index: 1;
    background: white;
    padding-bottom: 30px;
    &:before, &:after  {
      background: -webkit-gradient(linear, left top, right top, from(white), to(rgba(255, 255, 255, 0)));
      background: linear-gradient(to right, white 0%, rgba(255, 255, 255, 0) 100%);
      background:  ${props => props.isMobile ? 'transparent' : '-webkit-gradient(linear, left top, right top, from(white), to(rgba(255, 255, 255, 0)))'};
      background:  ${props => props.isMobile ? 'transparent' : 'linear-gradient(to right, white 0%, rgba(255, 255, 255, 0) 100%);'};
      content: "";
      height: 100%;
      position: absolute;
      width:  ${props => props.isMobile ? MOBILE_SLIDE_WIDTH : SLIDE_WIDTH}px;
      z-index: 2;
    }
    &:before {
      left: 0;
      top: 0;
    }
    &:after {
      right: 0;
      top: 0;
      -webkit-transform: rotateZ(180deg);
      transform: rotateZ(180deg);
    }
`;

const Partner = styled.img`
  max-width: ${props => props.isMobile ? 130 : 170}px;
  display: flex;  
  max-height: 8vh;
`;

const Partners = styled.div`
  font-family: Muli, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
  sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  span {
    color: #212656;
    font-size:  ${props => props.isMobile ? 1.6 : 2}em;
   
    .animate {
      animation: 1s ${fadeInUpAnimation};
    }
  }
  margin-top: 20px;

`;
const PartnerPage = styled.div`
  height: 15vh;
`;

const PartnerCarousel = ({ animate }) => {
//  const isTablet = useMediaQuery({ query: '(max-width: 1224px)' })
  const isMobile = useMediaQuery({ query: '(max-width: 600px)' });

  return (
    <PartnerPage>
      <Partners isMobile={isMobile}>
        <span className={animate ? 'animate' : 'init'}>Our Partners</span>
      </Partners>
      <Slider className={'slider'} isMobile={isMobile}>
        <SlideTrack isMobile={isMobile}>
          {[...Array(8).keys()].map((i) => (
            <Slide isMobile={isMobile}>
              <Partner
                isMobile={isMobile}
                fluid
                src={process.env.REACT_APP_S3_URL + '/images/partners/partner' + (i + 1) + '.png'}
              />
            </Slide>
          ))}

          {[...Array(8).keys()].map((i) => (
            <Slide isMobile={isMobile}>
              <Partner
                isMobile={isMobile}
                fluid
                src={process.env.REACT_APP_S3_URL + '/images/partners/partner' + (i + 1) + '.png'}
              />
            </Slide>
          ))}
        </SlideTrack>
      </Slider>
    </PartnerPage>

  );
};

export default PartnerCarousel;
